// TODO: Move this logic into a shared lib once tested

import { isLocal } from 'src/modules/datadog/datadog-config';

/**
 * Get IP address using public OSS API. API is used because window.RTCPeerConnection
 * is no longer able to be used for getting IP
 * @see https://www.ipify.org/
 * @see https://github.com/rdegges/ipify-api
 * @returns - IP address
 */
async function ipAddressFromApi(): Promise<string | undefined> {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const responseData = await response.json();
    return responseData.ip;
  } catch {
    return undefined;
  }
}

// Prefixes to block
const ipPrefixes = [
  '13.34.', // 700+ AWS rages
  // AWS us-east-1
  '44.202.79.', // 44.202.79.128/25
  '44.210.201.', // 44.210.201.0/24
  '44.210.202.', // 44.210.202.0/24
  '44.210.246.', // 44.210.246.64/26
  '44.210.6', // 44.210.64.0/22
  // AWS us-east-2
  '3.145.232.', // 3.145.232.192/26
  '3.145.242.', // 3.145.242.0/24
  '3.145.234.',
  '3.145.31.', // 3.145.31.128/26
  '13.34.43.', // 13.34.43.96/27
  '13.34.52.', // 13.34.52.0/27
  '3.18.188.',
  // AWS us-east-2-compute
  '3.145.224.',
  '3.145.225.',
  // AWS ca-central-1
  '3.99.124.', // 3.99.124.0/26
  '3.99.193.',
  '3.99.200.',
  '3.99.253.',
  // AWS us-west-1
  '52.93.178.', // 52.93.178.234/32
  '3.101.23', // 3.101.232.0/22
  '3.101.100.', // 3.101.100.128/25
  '3.101.114.', // 3.101.114.0/26, 3.101.114.64/26
  '3.101.157.', // 3.101.157.128/25
  '3.101.159.', // 3.101.158.0/23
  '3.101.160.', // 3.101.160.240/29, 3.101.160.44/30
  '3.101.204.',
  '3.101.205.',
  '3.101.209.',
  '3.101.212.',
  '52.9.0.', // 52.9.0.0/16
  '52.9.13.',
  '52.9.139.',
  // AWS us-west-2
  '35.92.124.', // 35.92.124.192/26
  '35.92.27.',
  '35.89.72.', // 35.89.72.0/25
  '35.89.46.',
  '35.89.47.',
  '44.210.110.',
  // AWS us-west-2 compute
  '52.89.221.',
  // AWS compute-1
  '44.202.178.',
  '44.202.180.',
  '44.202.181.',
  '44.210.180.',
  // Germany
  '3.121.22.', // eu-central-1 compute
];

// regex patterns to identify known bot instances
const botPattern =
  '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';

/**
 * Get whether or not Datadog RUM session should be enabled. Prevents
 * recording sessions for bots using Linux + Chrome by first checking userAgent
 * for known bots, then falling back to IP address based blocking for known AWS ips ranges.
 * Also prevents recording sessions in a local or test environment.
 * @returns - Boolean of whether or not Datadog RUM session should enabled
 */
export async function getRumSessionEnabled(): Promise<boolean> {
  // Always disable if in local dev or automated tests
  if (isLocal || (typeof window !== 'undefined' && window.Cypress)) {
    return false;
  }

  // Always enable if not on Linux + Chrome
  if (
    typeof navigator === 'undefined' ||
    !navigator.userAgent.includes('Linux') ||
    !navigator.userAgent.includes('Chrome')
  ) {
    return true;
  }

  // Do not record session userAgent matches a known bot
  const matchesBot = new RegExp(botPattern, 'i').test(navigator.userAgent);
  if (matchesBot) return false;

  try {
    const ipAddress = await ipAddressFromApi();
    // Record session if IP address not found
    if (!ipAddress) return true;

    // Do not record session if ip matches prefixes
    if (ipPrefixes.some((ipPrefix) => ipAddress.startsWith(ipPrefix)))
      return false;

    // Otherwise record session
    return true;
  } catch {
    return true; // Record session if there is an error determining IP address
  }
}
